<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
#app {
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <div class="">
    <h1 class="heading">DOUBLE<br />THEORY</h1>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.heading {
  font-family: "Rubik Mono One", sans-serif;
  color: #eeeeee;
  font-size: 10vw;
  line-height: 0.87;
}
</style>
